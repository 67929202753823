<template>
  <!-- Login -->
  <div class="auth-wrapper auth-v1 px-1">
    <span class="bg" />
    <div class="auth-inner py-2">
      <b-card
        id="page-login"
        border-variant="transparent"
        bg-variant="transparent"
        class="mb-0"
      >
        <b-link class="d-block">
          <div class="d-flex justify-content-center align-items-center mb-1">
            <b-img-lazy
              width="150"
              class="mb-1"
              fluid
              :src="require('@/assets/images/logo/kia-white.png')"
            />
          </div>
        </b-link>

        <div class="mb-1 font-weight-bolder">
          <b-card-title class="mb-1 text-white text-center">
            {{ $t("message.resetPassword") }}
          </b-card-title>
        </div>
        <b-card-text class="mb-2 text-white">
          {{ $t("message.resetPasswordDescription") }}
        </b-card-text>

        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="recaptchaResetPassword"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label class="text-white" for="password">
                  {{ $t("message.newPassword") }}
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Contraseña"
                rules="required|password|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="password1FieldType"
                    class="form-control-merge formInput text-white"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                    onpaste="return false"
                    :disabled="tokenExpired === 1 ? true : false"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label class="text-white" for="confirmPassword">
                  {{ $t("message.confirmPassword") }}
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Confirmar contraseña"
                rules="required|password|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="confirmPassword"
                    v-model="confirmPassword"
                    :type="password2FieldType"
                    class="form-control-merge formInput text-white"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Confirm password"
                    onpaste="return false"
                    :disabled="tokenExpired === 1 ? true : false"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <div></div>

                <b-link :to="{ name: 'login' }">
                  <small class="text-white">
                    {{ $t("message.backLogin") }}
                  </small>
                </b-link>
              </div>
            </b-form-group>
            <div class="text-center mt-3">
              <b-button
                v-if="loading == false"
                variant="light"
                type="submit"
                :disabled="invalid"
                class="font-weight-bolder px-3"
              >
                <div>{{ $t("message.establish") }}</div>
              </b-button>

              <b-spinner v-else />
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import {
  BCard,
  BSpinner,
  BButton,
  BForm,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  VBTooltip,
  BImgLazy,
  BCardText,
  BCardTitle,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email, alphaDash } from "@validations";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { updatePassword } from "@core/api/request/login";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import jwt from "jsonwebtoken";
import i18n from "@/libs/i18n";
document.oncontextmenu = function () {
  return false;
};

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    // BSV
    BCard,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BLink,
    BImgLazy,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BSpinner,
    BCardText,
    BCardTitle,
  },
  created() {
    localize("es");
  },
  data() {
    return {
      userEmail: null,
      password: null,
      confirmPassword: null,
      status: "",
      required,
      loading: false,
      email,
      sideImg: null,
      selected: "a",
      token: null,
      userData: null,
      tokenExpired: false,
      options: [
        { value: "a", text: "Contract Manager" },
        { value: "b", text: "Digital Assets Manager" },
        { value: "c", text: "Budget Manager" },
      ],

      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  mounted() {
    this.getData();

    localize("es");
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
  },
  methods: {
    decodeJWT(token) {
      const tok = jwt.decode(token);
      // console.log("token data", tok);

      const dateExp = new Date(0);
      // console.log("dat", dateExp);

      dateExp.setUTCSeconds(tok.exp);

      // console.log("fecha expiracion", dateExp);
      const dateNow = new Date();
      // console.log("date now", dateNow);

      if (dateExp < dateNow) {
        // console.log("token Expirado");
        this.alertToastError(
          "danger",
          `El tiempo para cambiar la contraseña ha expirado, debe repetir el proceso`
        );
        this.tokenExpired = 1;
        // console.log("token ", this.tokenExpired);
      } else {
        // console.log("token no Expirado");
        this.tokenExpired = 2;
        this.userData = tok;
      }
    },
    getData() {
      const url = window.location.search;
      const urlParametro = new URLSearchParams(url);
      const token = urlParametro.get("token");
      // console.log("token de acceso mounte", token);

      var segments = token.split(".");

      // console.log("hola siy bata", segments);

      if (segments.length !== 3) {
        this.alertToastError(
          "danger",
          `La llave no es correcta, ingrese de nuevo a su correo y verifique`
        );
        this.tokenExpired = 1;
        // console.log("token ", this.tokenExpired);
      } else this.decodeJWT(token);
      // this.userData = this.decodeJWT(token);

      //  console.log("hola siy bata", this.userData.email);
    },
    alertToast(type = null, title = null, icon = null) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: icon,
            variant: type,
            text: null,
          },
        },
        {
          position: "top-right",
        }
      );
    },

    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },

    resetPassword() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          if (this.password == this.confirmPassword) {
            this.loading = true;

            // console.log("efsfsdf bbbbb", this.userData);
            updatePassword({
              email: this.userData.email,
              newPassword: this.confirmPassword,
            })
              .then((response) => {
                console.log("Response ", response);

                this.alertToast(
                  "success",
                  "Su contraseña ha sido actualizada con éxito",
                  "CheckIcon"
                );

                this.userEmail = "";
                this.password = "";
                this.confirmPassword = "";
                this.$router.push({ name: "login" });
              })
              .catch((error) => {
                console.log(error.response);
                this.alertToast(
                  "danger",
                  "Ha ocurrido un error al actualizar la contraseña",
                  "XIcon"
                );
                this.loading = false;
                // this.$refs.loginForm.setErrors(error.response.data.error)
              });
          } else {
            this.alertToast(
              "danger",
              "Las contraseñas introducidas no coinciden",
              "XIcon"
            );
          }
        }
      });
    },

    recaptchaResetPassword() {
      grecaptcha.ready(() => {
        grecaptcha
          .execute("6LeJ5MQmAAAAAHY40ZyJb8PBOK9GiZw_RboBAKBc", {
            action: "submit",
          })
          .then((token) => {
            // console.log(token);
            this.resetPassword();
          })
          .catch((error) => console.log(error));
      });
    },

    alertToastError(type = null, title = null, message = null) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          icon: "XIcon",
          variant: type,
          text: message,
        },
      });
    },
    alertToastExit(type = null, title = null, message = null) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title,
          icon: "CheckIcon",
          variant: type,
          text: message,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../../assets/images/pages/login/background.png") center
    center;
  background-size: cover;
}
</style>
